<template>
  <b-card class="provider-wallet">
    <b-row>
      <b-col
        md="3"
        class="mb-2"
      >
        <b-card
          no-body
          class="card-wallet-info p-1 mb-0"
        >
          <b-card-header class="pb-1">
            <h5 class="mb-0">
              {{ $t('Balance') }}
            </h5>
          </b-card-header>
          <b-card-body v-if="walletList">
            {{ walletList.wallet_balance }} {{ providerCurrency }}
          </b-card-body>
        </b-card>
      </b-col>
      <b-col md="9">
        <b-card-code
          :title="$t('Add Money in Wallet')"
          class="mb-0"
        >
          <b-row>
            <b-col md="4">
              <b-form-group
                :label="$t('Enter Amount (AED)')"
                label-for="walletAmount"
              >
                <b-form-input
                  id="walletAmount"
                  v-model="amountToAdd"
                  type="number"
                  placeholder="Enter amount"
                />
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                :label="$t('Select Card')"
                label-for="ddlSelectCard"
              >
                <v-select
                  v-model="selectedCard"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="cardOptions"
                  :reduce="val => val.value"
                  :clearable="false"
                  input-id="ddlSelectCard"
                />
              </b-form-group>
            </b-col>
            <b-col
              md="4"
              class="my-auto"
            >
              <b-button
                variant="primary"
                class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              >
                {{ $t('Add Money') }}
              </b-button>
            </b-col>
          </b-row>
        </b-card-code>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <good-data-table
          ref="walletGrid"
          :columns="walletColumns"
          :total-column="'response.responseData.total'"
          :data-column="'response.responseData.data'"
          :api-endpoint="'/provider/wallet'"
        />
      </b-col>
    </b-row>
    <b-modal
      id="transactionDetailsModal"
      v-model="showTransactionDetails"
      :no-close-on-backdrop="true"
      :title="$t('Transaction Details')"
      size="lg"
      hide-footer
      @hidden="hideTransactionDetails"
    >
      <b-col
        cols="12"
        md="12"
        class="table-responsive"
      >
        <table class="table table-bordered table-striped table-hover">
          <thead>
            <tr>
              <th>{{ $t('Description') }}</th>
              <th>{{ $t('Type') }}</th>
              <th>{{ $t('Amount') }}</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item, index) of currentTransactionView"
              :key="index"
            >
              <td>{{ item.transaction_desc }}</td>
              <td>{{ item.type == 'C' ? 'Credit' : 'Debit' }}</td>
              <td>{{ item.amount }} {{ providerCurrency }}</td>
            </tr>
          </tbody>
        </table>
      </b-col>
    </b-modal>
  </b-card>
</template>

<script>
import { BRow, BCol, BCard, BFormGroup, BFormInput, BCardHeader, BCardBody, BButton, BModal } from 'bootstrap-vue'
import vSelect from 'vue-select'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import GoodDataTable from '@/@core/layouts/shared/GoodDataTable.vue'
import { showErrorNotification } from '@/@core/comp-functions/ui/app'
import { useProviderUi } from './useProvider'

export default {
  components: {
    BRow,
    BCol,
    BModal,
    GoodDataTable,
    BCard,
    BCardCode,
    BFormGroup,
    BFormInput,
    BCardHeader,
    BCardBody,
    vSelect,
    BButton,
  },
  data() {
    return {
      walletColumns: [
        {
          label: 'Transaction Id',
          field: 'transaction_alias',
        },
        {
          label: 'Description',
          field: 'transaction_desc',
        },
        {
          label: 'Date & Time',
          field: 'created_time',
        },
        {
          label: 'Status',
          field: 'type',
          rField: 'type_text',
          align: 'center',
          useResolver: true,
          useRenderer: true,
          renderer: props => {
            if (props.type === 'C') {
              return 'Credit'
            }
            return 'Debit'
          },
        },
        {
          label: 'Amount',
          field: 'amount',
          rField: 'amount_text',
          useRenderer: true,
          useResolver: true,
          renderer: props => `${props.amount}`,
        },
        {
          label: 'Action',
          field: 'action',
          sortable: false,
          type: 'dropdown',
          actions: [
            {
              isIcon: true,
              iconHtml: this.$helpers.getIcons('view'),
              text: 'View',
              action: props => {
                this.viewWalletTransaction(props)
              },
            },
          ],
        },
      ],
      walletList: null,
      cardOptions: [],
      selectedCard: '0',
      amountToAdd: 0,
      currentTransactionView: [],
      showTransactionDetails: false,
    }
  },
  computed: {
    providerCurrency() {
      if (!this.walletList) return ''
      return this.walletList.currency_symbol
    },
  },
  mounted() {
    this.getProviderTransactionList()
    this.getProviderCards()
  },
  methods: {
    hideTransactionDetails() {
      this.showTransactionDetails = false
    },
    viewWalletTransaction(props) {
      this.currentTransactionView = props.transactions
      this.showTransactionDetails = true
    },
    getProviderTransactionList() {
      const { getProviderList } = useProviderUi()

      getProviderList()
        .then(({ data }) => {
          if (data.statusCode === '200') {
            this.walletList = data.responseData
          }
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    getProviderCards() {
      const { providerCards } = useProviderUi()

      providerCards().then(({ data }) => {
        if (data.statusCode === '200') {
          this.cardOptions = []
          this.cardOptions = data.responseData.map(x => ({
            label: `**** **** **** ${x.last_four}`,
            value: x.id,
            payload: x,
          }))
          this.cardOptions.unshift({
            label: 'Select Card',
            value: '0',
          })
        }
      }).catch(error => {
          showErrorNotification(this, error)
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
@import '@core/scss/vue/libs/vue-select.scss';

[dir] .vs__dropdown-toggle {
  border: 1px solid #d8d6de;
}

#transactionDetailsModal .modal-body {
  max-height: 85vh;
  overflow-y: auto;
}
</style>
